* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.Portfolio {
  height: 100vh;
  width: 100vw;
}

.DividendView{
  height: 100vh;
  width: 100vw; 
}

.CalculatorView{
  height: 100vh;
  width: 100vw; 
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
}

.row{
    height: 100%;
}



.SideBar {
  height: 100%;
  width: 250px;
  min-width: 250px;
  background-color: #212529;
  color: hsla(0, 0%, 100%, 0.55);
  float: left;
}

.SideBarList{
  height: auto;
  padding: 0;
  width: 100%;
}

.SideBarList .SideBarRow{
  width: 100%;
  height:60px;
  border: 1px solid black;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: SideBarRow;
  color:white;
  justify-content: center;
  align-items: center;
  font-family: 'PT Sans', sans-serif;

}

.SideBarList .SideBarRow:hover{
  cursor: pointer;
  background-color: #49535f;
  transition: 250ms;
}

.SideBarList #active{
  background-color: #49535f;
}

.SideBarRow #icon{
  flex:30%;
  display: grid;
  place-items: center;
}

.SideBarRow #title{
  flex:70%;
}
